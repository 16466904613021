import Image from 'next/image';
import Link from 'next/link';
import { operatingCompanyUrl, policyUrl, termUrl } from '@/constants/domain';
import { useLocationHref } from '@/hooks/useLocationHref';
import ShareButton from '@/components/Button/ShareButton';

function Footer() {
    const shareUrl = encodeURIComponent(useLocationHref());

    return (
        <>
            <footer className="h-auto bg-lightGrey pt-6 pb-3 md:py-6 w-full">
                <div className="md:flex items-center h-full w-full">
                    <div className="ml-4 md:ml-6">
                        <div className="font-hiragino-sans text-primary text-xs leading-[18px]">
                            CONTACT
                        </div>
                        <div className="font-hiragino-sans text-primary text-xs leading-[18px]">
                            E-MAIL :
                            <a
                                href="mailto:support@pro-connect.jp"
                                className="text-primary hover:underline"
                            >
                                support@pro-connect.jp
                            </a>
                        </div>
                        <div className="font-hiragino-sans text-primary text-xs leading-[18px]">
                            <a
                                className="text-primary hover:underline"
                                href={termUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                利用規約
                            </a>
                            <span className="mx-1">|</span>
                            <a
                                className="text-primary hover:underline"
                                href={policyUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                プライバシーポリシー
                            </a>
                            <span className="mx-1">|</span>
                            <a
                                className="text-primary hover:underline"
                                href={operatingCompanyUrl}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                © 2023 WorkX, Inc.
                            </a>
                            <span className="ml-1">|</span>
                        </div>
                    </div>
                    <div className="flex mr-7 ml-3 md:ml-auto mt-4 md:mt-0">
                        <Link
                            href="https://privacymark.jp/"
                            passHref
                            target="_blank"
                            rel="noopener noreferrer"
                            className="hover:opacity-70"
                        >
                            <Image
                                src={'/images/p-mark-jp.png'}
                                alt={'p-mark'}
                                width={100}
                                height={100}
                                className="hover:opacity-70 m-[25px] h-[100px] w-[100px]"
                            />
                        </Link>

                        <div className="flex items-center ml-5">
                            <ShareButton
                                type={'roundedTwitterPrimary'}
                                className={'w-[36px] h-[36px] mr-[12px]'}
                                url={shareUrl}
                            />
                            <ShareButton
                                type={'roundedFacebookPrimary'}
                                className={'w-[36px] h-[36px]'}
                                url={shareUrl}
                            />
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
